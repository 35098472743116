<template>
  <section id="christian">
    <v-sheet
      :height="$vuetify.breakpoint.smAndUp ? '50vh' : '75vh'"
      color="primary"
      tile
    >
      <!-- <v-img
      :key="image"
      :src="image"
      gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .50)"
      height="100%"
      position="top"
    > -->
      <v-container fill-height fluid class="py-0">
        <!-- <v-row class="fill-height"> -->
        <v-row>
          <v-col justify="center" align="center" class="ml-4">
            <h1 class="mb-2 white--text big-text text-uppercase">Ministry</h1>
            <v-divider class="mb-4" color="white" width="200"> </v-divider>
            <p class="white--text mb-0" style="font-size: 1.2em">
              Doug &amp; Joyce have been involved in Christian ministry for over
              40 years, areas of specialty include pastors, missionaries,<br />
              Christian leaders, and working with Christian organizations.
            </p>
          </v-col>
        </v-row>
      </v-container>
      <!-- </v-img> -->
    </v-sheet>
    <v-container fluid>
      <v-row>
        <!-- <v-col class="pa-0" cols="6">
          <v-img
              src="/static/asian-couple-argument.jpg"
              gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .10)"
            />
      </v-col>
      <v-col class="pa-0" cols="6">
            <v-img
              src="/static/asian-couple-cooking-in-the-kitchen.jpg"
              gradient="to top, rgba(0,0,0, .10), rgba(0,0,0, .10)"
            />
      </v-col> -->
        <!-- <v-col
          cols="12"
          align="center"
          class="mt-5"
        >
            <h1 class="big-text text-uppercase">Ministry</h1>

            <p>Having been involved in Christian ministry for over 40 years, areas of specialty include pastors, missionaries, Christian leaders, and working with Christian organizations</p>
                Book Session
            </v-btn> -->
        <!-- </v-col> -->
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12" offset-md="2">
          <v-sheet>
            <v-row align="center">
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 3">
                <v-img
                  style="border-radius: 10px;"
                  src="/static/churchoutside.png"
                  alt="old missionary church outside"
                />
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 9">
                <v-row>
                  <v-col align="center" cols="12">
                    <h2>Missionaries</h2>
                    <v-divider class="mt-3"></v-divider>
                  </v-col>
                  <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'mx-6' : ''"
                    cols="12"
                  >
                    <p>
                      Doug worked as a missionary for 5 years as a single man,
                      where he eventually met his wife, Joyce in South America.
                      (see <a href="/about/#doug">Doug Feil's bio</a>) Joyce
                      grew up as a missionary kid in Brazil, then worked in
                      missions as a single woman which is when we met (see
                      <a href="/about/#joyce">Joyce Feil's bio</a>). After
                      marriage, we have continued to work together in missions
                      up to the present. Doug's counseling experience is across
                      the entire life cycle of missionaries. Doug &amp; Joyce
                      are experienced in training missionaries and churches on
                      topics such as: Preparing to do missions work, adjusting
                      to the ministry setting, life in ministry at their field
                      location, crisis challenges, raising third culture kids,
                      building effective &amp; strategic church missions
                      programs and workers returning to their passport country.
                    </p>
                  </v-col>
                  <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'mx-6' : ''"
                    cols="12"
                  >
                    <h3>Areas of specialty here include:</h3>
                    <ul>
                      <li
                        v-for="(item, index) in areasofspecialtyministry"
                        :key="index"
                      >
                        {{ item }}
                      </li>
                    </ul>
                  </v-col>
                  <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'mx-6' : ''"
                    cols="12"
                  >
                    <p>
                      In partnership with Joyce, we together help missionaries
                      deal with member care issues and/or difficulties with
                      sending organizations, supporting churches and even donor
                      relationships. Joyce and Doug launched a specific ministry
                      to the mission’s community called
                      <a
                        href="https://southwestcounseling.org/services/missionary-care/"
                        >Missionary Care Services, International (MCS)</a
                      >
                      which continues to the present serving this population
                      from its office in Littleton, CO. Doug served with MCS as
                      a Director and Counselor for a total of 30 years.
                    </p>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col :cols="$vuetify.breakpoint.mdAndUp ? 8 : 12">
          <v-sheet>
            <v-row>
              <v-col align="center" cols="12">
                <h2>Pastors</h2>
                <v-divider class="mt-3"></v-divider>
              </v-col>
              <v-col
                :class="$vuetify.breakpoint.lgAndUp ? 'mx-6' : ''"
                cols="12"
              >
                <p>
                  Doug has extensive experience in areas of burnout, marital
                  conflict, mediation regarding conflicts with other church
                  leaders/elders, also personal areas such as depression, sexual
                  struggles and issues relating to calling or ministry
                  effectiveness. Doug served as a pastor for 4 years in Uruguay
                  and has been active in church ministries his whole life.
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                :class="$vuetify.breakpoint.lgAndUp ? 'mx-6' : ''"
                :cols="$vuetify.breakpoint.mdAndDown ? 12 : 1"
              >
                <v-icon size="100" color="primary">mdi-cash-multiple</v-icon>
              </v-col>
              <v-col :cols="$vuetify.breakpoint.mdAndDown ? 12 : 4">
                <v-row>
                  <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'mx-6' : ''"
                    cols="12"
                  >
                    <h3>Financial Assistance</h3>
                    <p>
                      Using <a href="http://www.urbanskye.org/">Urban Skye</a>,
                      We are raising ministry funding to be able to create a
                      scholarship fund to provide
                      <strong>reduced rate counseling</strong> for
                      missionaries/global workers and additionally consulting
                      services for churches and sending organizations in the
                      areas of member care and effective global outreach
                      programs.
                    </p>
                    <v-btn
                      class="mr-4 primary"
                      href="http://www.urbanskye.org/"
                      target="_blank"
                      >Learn More</v-btn
                    >
                    <v-btn
                      href="https://urbanskye.givingfuel.com/doug-feil"
                      target="_blank"
                      >Donate Now</v-btn
                    >
                  </v-col>
                  <v-col
                    :class="$vuetify.breakpoint.lgAndUp ? 'mx-6' : ''"
                    cols="12"
                  >
                    <v-small class="caption"
                      >*by pushing these buttons you will leave this
                      website</v-small
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-sheet>
        </v-col>
        <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" offset-md="2">
          <v-row>
            <v-col cols="12">
              <h2 style="color: grey;" class="text-uppercase">
                Related Blog Articles
              </h2>
            </v-col>
            <v-col v-for="(article, index) in Articles" :key="index" cols="2">
              <v-sheet color="white" elevation="1" class="rounded-xl">
                <v-row>
                  <v-col align="center" cols="12" class="mt-0 pt-0">
                    <v-img
                      style="border-top-left-radius: 25px; border-top-right-radius: 25px;"
                      :src="article.img"
                    ></v-img>
                  </v-col>
                  <v-col align="center" cols="12" class="pt-0">
                    <h3>{{ article.title }}</h3>
                    <v-divider class="mt-3"></v-divider>
                  </v-col>
                  <v-col cols="12" class="ml-2 px-8">
                    <p>{{ article.description }}</p>
                    <v-btn
                      class="pl-0"
                      color="primary"
                      :onclick="`location.href='${article.url}';`"
                      text
                      >Read More</v-btn
                    >
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <AccentBar v-if="$vuetify.breakpoint.mdAndUp" />
    <!-- <MidImage :bartype="'happy'"/> -->
    <ContactBar />
  </section>
</template>

<script>
import blogArticles from "../blogarticles.json";
export default {
  metaInfo() {
    return {
      title: "Ministry Counseling and Consulting",
      titleTemplate: "Ministry | Feil Counseling and Consulting",
      htmlAttrs: {
        lang: "en-US"
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Having been involved in Christian ministry for over 40 years, areas of specialty include pastors, missionaries, Christian leaders, and working with Christian organizations"
        },
        { property: "og:site_name", content: "Feil Counseling and Consulting" },
        { property: "og:image", content: "/static/feil_logo_vertical.png" },
        {
          property: "twitter:image:src",
          content: "/static/feil_logo_vertical.png"
        }
      ]
    };
  },
  components: {
    Heading: () => import("@/components/Heading"),
    Testimonial: () => import("@/components/Testimonial"),
    GetAQuote: () => import("@/components/GetAQuote"),
    ContactBar: () => import("@/components/ContactBar"),
    MidImage: () => import("@/components/MidImage"),
    AccentBar: () => import("@/components/AccentBar")
  },

  data: () => ({
    testimonial: {
      blurb:
        "With Alpha Construction managing all of our customer-facing services and ASCC, helping us monitor and understand building performance, Abocado is in great shape to create and release new products.",
      person: "Jay Oakrson",
      title: "CEO Abocado"
    },
    areasofspecialtyministry: [
      "Training missions teams",
      "Candidate assessments prior to deployment",
      "Mental health and wellness check ups",
      "Crisis counseling",
      "Marriage and individual issues",
      "Conflict resolution with other workers and/or organizations",
      "Critical incident debriefing",
      "Trauma response ",
      "The reentry process"
    ]
  }),
  computed: {
    Articles() {
      let articles = blogArticles.filter(item => {
        return item.page == "ministry";
      });

      return articles;
    }
  }
};
</script>
<style scoped>
.big-text {
  font-size: 3em;
}
.midbar-text {
  font-size: 1.5em;
}
</style>
